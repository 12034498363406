<template>
<v-container v-if="loggedIn" style="position: absolute; left: 0; right: 0; margin-left: auto; margin-right: auto; width: 600px">
  <!-- Search box -->
  <v-text-field
    v-model="search"
    label="Search"
    dense
    clearable
    solo
    hide-details
    style="margin: 0; padding: 0;"
    @keyup.enter="searchItemFlo(search)"
    @input="debounceSearchItemFlo"
    @click="() => this.$emit('openSearchResults')"
    @click:clear="() => this.$emit('clearSearchResults')"
  >
    <template v-slot:append>
      <v-select
        v-model="searchType"
        :items="['Any', 'Product', 'Brand', 'Website']"
        solo
        flat
        hide-details
        dense
        :style="`margin: 0; padding: 0; ${searchTypeWidth}`"
        @change="searchItemFlo(search)"
      ></v-select>
    </template>
    
    <template v-slot:append-outer class="ma-0">
      <v-btn @click="searchItemFlo(search)" text small style="padding: 0; margin: -7px 0 -4px -9px; height: 40px; width: 40px"><v-icon>mdi-magnify</v-icon></v-btn>
    </template>
  </v-text-field>
</v-container>
</template>

<script>
import _debounce from 'lodash/debounce';
export default {
  props: {
    loggedIn: Boolean,
  },
  data() {
    return {
      search: '',
      searchType: 'Any',
    }
  },
  methods: {
    searchItemFlo(value) {
      if (value) {
        this.$emit('searchItemFlo', value, this.searchType)
      }
    },
    debounceSearchItemFlo: _debounce(function () {
      this.$emit('searchItemFlo', this.search, this.searchType);
    }, 600)
  },
  computed: {
    searchTypeWidth() {
      let width = ''
      switch(this.searchType) {
        case 'Any': width = '83'; break
        case 'Product': width = '115'; break
        case 'Brand': width = '108'; break
        case 'Website': width = '116'; break
      }
      return `width: ${width}px;`
    }
  }
}
</script>

<style scoped>
.v-input__append-outer {
  margin-top: 0
}
</style>