<template>
  <v-navigation-drawer
    v-if="loggedIn"
    permanent
    app
    dark
    clipped
    color="grey darken-3"
    :mini-variant="showSideNavMini"
    :expand-on-hover="showSideNavMini"
    :key="showSideNavMini"
  >
    <v-list nav>
      <v-list-item-group mandatory>
        <v-list-item v-for="(page, i) in pages" :key="i" :to="page.path" class="list-item">
          <v-list-item-icon><v-icon>{{ page.icon }}</v-icon></v-list-item-icon>
          <v-list-item-title>{{ page.text }}</v-list-item-title>
        </v-list-item>
      </v-list-item-group>
    </v-list>

    <template v-slot:prepend>
      <v-list nav class="pb-0">
        <v-list-item @click="showSideNavMini = !showSideNavMini">
          <v-list-item-icon><v-icon>mdi-menu</v-icon></v-list-item-icon>
          <v-list-item-title>{{ showSideNavMini ? 'Expand' : 'Collapse' }}</v-list-item-title>
        </v-list-item>
      </v-list>
    </template>

    <template v-slot:append>
      <v-list nav>
        <v-list-item to="/settings" class="list-item">
          <v-list-item-icon><v-icon>mdi-cog</v-icon></v-list-item-icon>
          <v-list-item-title>Settings</v-list-item-title>
        </v-list-item>
      </v-list>
    </template>
  </v-navigation-drawer>
</template>

<script>
export default {
  props: {
    loggedIn: Boolean,
  },
  data() {
    return {
      showSideNavMini: true,
      pages: [
        { text: 'Home', icon: 'mdi-home', path: '/' },
        { text: 'Products', icon: 'mdi-barcode', path: '/products' },
        { text: 'Brands', icon: 'mdi-label', path: '/brands' },
        { text: 'Websites', icon: 'mdi-sitemap', path: '/sites' },
        { text: 'Import/Export', icon: 'mdi-swap-vertical', path: '/import-export' },
      ]
    }
  }
}
</script>

<style>
.list-item.v-list-item:focus::before {
  opacity: 0 !important;
}
</style>