import { mapActions } from "vuex";
export const userCore = {
  computed: {
    user() {
      return this.$store.getters.getUser;
    },
    loggedIn() {
      return this.$store.getters.isLoggedIn;
    },
  },
};

export const userLogic = {
  methods: {
    ...mapActions({
      updateUser: "updateUser",
      updateImageSettings: "updateImageSettings",
    }),
  },
};
