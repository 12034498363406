import Vue from "vue";
import Vuex from "vuex";
import user from "./user";
import general from "./general";
import brands from "./brands";
import websites from "./websites";
import products from "./products";
import metrics from "./metrics";
import notifications from "./notifications";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {},
  mutations: {},
  actions: {},
  modules: {
    user: user,
    general: general,
    brands: brands,
    websites: websites,
    products: products,
    metrics: metrics,
    notifications: notifications,
  },
});
