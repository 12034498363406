<template>
  <v-card style="width: 300px; margin-right: 20px; right: 0; position: fixed; z-index: 6; top: 10;" v-if="show" v-click-outside="hideUserInfo">
    <div style="text-align: center">
      <h3 style="padding: 5px;">User Info
        <span style="float: right;">
          <v-icon v-if="editing && changes" @click="saveChanges()">mdi-content-save-edit-outline</v-icon>
          <v-icon v-else-if="editing" @click="editing=false; changing_password=false">mdi-cancel</v-icon>
          <v-icon v-else @click="editing=true">mdi-square-edit-outline</v-icon>
        </span>
      </h3>
    </div>
    <v-divider></v-divider>
    <v-card-text v-if="editing">
      <v-form ref="user_edit">
      <v-row>
        <v-col cols="12" sm="6" md="6">
        <v-text-field dense v-model="new_user.givenName" label="Given Name" :rules="rules.field_required"></v-text-field>
        </v-col>
        <v-col cols="12" sm="6" md="6">
        <v-text-field dense v-model="new_user.familyName" label="Family Name" :rules="rules.field_required"></v-text-field>
        </v-col>
      </v-row>
      <v-text-field dense v-model="new_user.username" label="Username" :rules="rules.field_required"></v-text-field>
      <v-text-field dense v-model="new_user.email" label="Email" :rules="rules.field_required"></v-text-field>
      <v-switch
        v-model="changing_password"
        @change="(e) => e ? {} : clearPassword()"
        label="Change Password"
        inset
        hide-details
        style="margin-top: 0"
      ></v-switch>

      <v-expand-transition>
        <v-text-field
          v-if="changing_password"
          v-model="new_user.current_password"
          :rules="current_password_rules"
          label="Current Password"
          :type="showPassword ? 'text' : 'password'"
          :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
          :append-outer-icon="'mdi-close'"
          @click:append="showPassword = !showPassword"
          @click:append-outer="clearPassword"
        ></v-text-field>
      </v-expand-transition>
      <v-expand-transition>
        <v-text-field
          v-if="changing_password"
          v-model="new_user.new_password"
          :rules="new_password_rules"
          label="New Password"
          :type="showPassword ? 'text' : 'password'"
          :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
          :append-outer-icon="'mdi-close'"
          @click:append="showPassword = !showPassword"
          @click:append-outer="clearPassword"
        ></v-text-field>
      </v-expand-transition>
      </v-form>
    </v-card-text>
    <v-card-text v-else>
      <div>
        Name: {{ user.givenName }} {{ user.familyName }}
      </div>
      <div>
        Username: {{ user.username }}
      </div>
      <div>
        Email: {{ user.email }}
      </div>
      <div>
      Role: {{ user.role }}
      </div>
      <!-- <v-select
        v-model="user.dashboard"
        :items="$store.getters.getDashboards"
        label="Dashboard"
        item-text="dashboard_name"
        item-value="dashboard_id"
        outlined
        filled
        hide-details
        background-color="white"
        class="pt-2"
        @change="updateUser({dashboard: $event})"
      ></v-select> -->
    </v-card-text>
    <v-divider></v-divider>
    <div style="text-align: center">
      <v-btn text block @click="logout">
        Logout
        <v-icon>mdi-logout</v-icon>
      </v-btn>
      <v-progress-linear
        :active="showLogoutLoader"
        :indeterminate="true"
        :query="false"
      ></v-progress-linear>
    </div>
  </v-card>
</template>

<script>
var clone = require('clone');
import { userLogic } from "@/mixins/userMixin.js";
export default {
  mixins: [userLogic],
  data() {
    return {
      new_user: clone(this.user),
      editing: false,
      changing_password: false,
      showLogoutLoader: false,
      showPassword: false,
      rules: {
        field_required: [v => !!v || 'Field is required']
      },
      current_password_rules: [v => !!v || "Current password is required"],
      new_password_rules: [
        v => !!v || "New Password is required",
        v => (v || '').length >= 8 || 'Password must be at least 8 characters long',
        v => /^(?=.*[A-Za-z])(?=.*\d)(?=.*[!@#$%^&*()_\-+={}[\]\\|;:'"?/>.<,])[A-Za-z\d!@#$%^&*()_\-+={}[\]\\|;:'"?/>.<,]{8,}$/.test(v) || 'Password must contain at least one letter, number and special character'
      ],
    }
  },
  props: {
    user: Object,
    show: Boolean,
  },
  computed: {
    changes() {
      return JSON.stringify(this.new_user) !== JSON.stringify(this.user);
    }
  },
  methods: {
    clearPassword() {
      this.$delete(this.new_user, 'current_password');
      this.$delete(this.new_user, 'new_password');
      this.$refs.user_edit.resetValidation();
    },
    getChanges() {
      let changes = {};
      for (var key in this.new_user) {
        if (this.new_user[key] !== this.user[key]) {
          changes[key] = this.new_user[key];
        }
      }
      // If changing password, add old username to changes
      if (this.changing_password) {
        changes["userNameOrEmail"] = this.user.username;
      }
      return changes;
    },
    saveChanges() {
      // If changes made & form valid
      if (this.changes && this.$refs.user_edit.validate()) {
        this.updateUser(this.getChanges());
      }
      this.editing = false;
      this.changing_password = false;
    },
    hideUserInfo() {
      this.$emit('update:show', false);
    },
    logout() {
      this.hideUserInfo();
      this.showLogoutLoader = true;
      this.$store.dispatch("logout")
        .then(() => {
          this.showLogoutLoader = false;
          this.$router.push("/login");
        })
        .catch(() => {
          this.showLogoutLoader = false;
          this.$router.push("/login");
        });
    },
  }
}
</script>