import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";
import VueResource from "vue-resource";
import VueFileAgent from "vue-file-agent";
import "vue-file-agent/dist/vue-file-agent.css";
import { SlickList, SlickItem } from "vue-slicksort";
import socketio from "socket.io-client";
import VueSocketIO from 'vue-socket.io';
import axios from 'axios';
Vue.prototype.$axios = axios

// Set Authentication header
axios.interceptors.request.use(function (config) {
  const token = store.getters.getToken;
  config.headers['X-Authorization'] = token;
  return config;
});

axios.interceptors.response.use(function (response) {
  return response;
}, (error) => {
  // If 401 response invalidate user
  if(error.response.status === 401) {
    store.dispatch("invalidateMe")
      .then(() => {
        store.dispatch('performingUpdate', false)
        // Then route user to login page
        router.push("/login").then(() => {
          store.dispatch('showSnackbar', {text: "Invalid Login. Please login again.", color: "warning", timeout: 2500})
        }).catch(()=>{});
      })
      .catch(err => {
          console.log(err);
      });
  // If 403 response show you don't have access
  } else if (error.response.status === 403) {
    store.dispatch('performingUpdate', false);
    store.dispatch('showSnackbar', {text: "Permission denied! You do not have permission to perform this action.", color: "error", timeout: 2500});
  } else if (error.response.status === 404) {
    router.push("/404");
  }
  return Promise.reject(error);
});

Vue.config.productionTip = false;
Vue.config.devtools = false;

Vue.use(new VueSocketIO({
  connection: socketio(process.env.VUE_APP_WEBSOCKET_URL),
  vuex: {
    store,
    actionPrefix: 'socket.',
    mutationPrefix: 'socket.'
  }
}));

Vue.use(VueResource);
Vue.use(vuetify);
Vue.use(VueFileAgent);
Vue.component("vfa-sortable-list", SlickList);
Vue.component("vfa-sortable-item", SlickItem);
Vue.component(SlickList);
Vue.component(SlickItem);
new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App),
}).$mount("#app");
