<template>
  <div>
    <v-speed-dial
      bottom
      right
      fixed
      :direction="'top'"
      :open-on-hover="false"
      :transition="'slide-y-reverse-transition'"
      class="speed-dial-wrapper"
    >
      <template v-slot:activator>
        <v-btn
          color="#007b7e"
          dark
          fab
        >
          <v-icon>
            mdi-help-circle-outline
          </v-icon>
        </v-btn>
      </template>
      <v-btn
        v-for="(button, i) in buttons" :key="i"
        small
        color="#017c7f"
        class="rounded-lg mr-2"
        elevation="2"
        outlined
        style="background-color: white"
        @click="performAction(button)"
      >
        {{ button.text }}
      </v-btn>
    </v-speed-dial>

    <!-- Action Dialogs -->
    <ActionDialog ref="submit_bug_dialog" title="Submit a bug" color="success" confirm_button_text="Submit">
      <template #default="{ options }">
        <v-text-field v-model="options.data.summary" label="Summary" outlined :rules="[v => !!v || 'Must have a summary']" @keydown.enter.prevent class="rounded-lg" />
        <v-textarea v-model="options.data.description" label="Description" placeholder="Full description of bug, including steps to reproduce." :rules="[v => !!v || 'Must have a description']" persistent-placeholder outlined hide-details class="rounded-lg" />
      </template>
    </ActionDialog>
  </div>
</template>

<script>
export default {
  mixins: [],
  components: {
    ActionDialog: () => import('@/components/ActionDialog.vue')
  },
  data: () => ({
  }),
  computed: {
    buttons() {
      let buttons = [
        {
          text: 'Quick Start',
          link: '/quick-start'
        },
        {
          text: 'Submit a bug',
          modal: 'submit_bug_dialog'
        }
      ]
      return buttons;
    }
  },
  methods: {
    performAction(button) {
      // Redirect to page if button has a link
      if (button.link !== undefined) {
        this.$router.push(button.link);
      // Open modal if button has a modal
      } else if (button.modal) {
        this.$refs[button.modal].showDialog().then(data => this.submitBug(data)).catch(() => {});
      }
    },
    async submitBug(data) {
      await this.$axios.post(`${process.env.VUE_APP_API_BASE_URL}/submit-bug`, data)
      .then(() => {
        this.$store.dispatch("showSnackbar", {text: `Bug submitted!`, color: "success", timeout: 2500});
      })
      .catch(err => {
        this.$store.dispatch("showSnackbar", {text: `There was an error submitting your bug, please try again or email help@itemflo.com.`, color: "error", timeout: 2500});
        console.log(err);
      });
    }
  }
};
</script>
<style scoped>
.speed-dial-wrapper >>> .v-speed-dial__list {
  align-items: end;
}
</style>