<template>
  <v-card elevation="4" outlined class="rounded-lg">
    <v-card-title class="py-2">
      Notifications
      <v-spacer/>
      <v-btn text small color="primary" @click="markNotificationsRead()">Mark all as read</v-btn>
    </v-card-title>
    <v-divider/>
    <v-card-text class="pa-0" style="max-height: 567px; overflow-y: auto; overflow-x: hidden;">
      <v-list two-line class="pa-0">
        <template v-for="(notification, i) in notifications">
          <router-link :to="`/notification${notification.notification_id}`" :key="i" style="text-decoration: none;">
            <v-hover v-slot="{ hover }" :disabled="notification.cancellable === false">
              <v-list-item @click="handleOnClick(notification)" active-class="">
                <v-sheet v-if="!notification.is_read" color="#007b7e" class="notification-status"></v-sheet>

                <v-progress-circular v-if="notification.status !== 'Complete'" indeterminate size=24 width=2 class="mr-3"></v-progress-circular>
                <v-list-item-content>
                  <v-list-item-title>{{ notification.title }}</v-list-item-title>

                  <v-list-item-subtitle>{{ notification.subtitle }}</v-list-item-subtitle>
                </v-list-item-content>

                <v-list-item-action>
                  <v-chip small outlined>{{ notification.status }}</v-chip>

                  <v-list-item-action-text>{{ notification.time_since }}</v-list-item-action-text>
                </v-list-item-action>

                <v-slide-x-reverse-transition>
                  <v-list-item-action class="cancel-action" v-if="notification.status !== 'Complete' && hover">
                    <v-btn block tile plain style="height: 100%" @click.prevent.stop="$socket.emit(`cancel_${notification.notification_id}`)">Cancel</v-btn>
                  </v-list-item-action>
                </v-slide-x-reverse-transition>
              </v-list-item>
            </v-hover>
          </router-link>

          <v-divider :key="`${i}_divider`" v-if="i < notifications.length-1"/>
        </template>

        <v-list-item v-if="notifications.length === 0">
          <v-list-item-content>
            <v-list-item-title>No New Notifications</v-list-item-title>
            <v-list-item-subtitle>You're all caught up! 🎉</v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>

      </v-list>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  props: {
    showNotifications: Boolean,
  },
  data() {
    return {
      notificationIntervalId: null,
    }
  },
  watch: {
    showNotifications(val) {
      if (val) {
        this.updateNotificationsTimeSince();
        this.notificationIntervalId = setInterval(() => {
          this.updateNotificationsTimeSince();
        }, 10000);
      } else {
        clearInterval(this.notificationIntervalId);
      }
    },
    notifications() {
      if (this.showNotifications) {
        this.$store.dispatch('markNotificationsAsSeen');
      }
    }
  },
  computed: {
    notifications() {
      return this.$store.getters.getNotifications;
    }
  },
  methods: {
    handleOnClick(notification) {
      this.$emit('update:showNotifications', false);
      if (!notification.is_read) {
        this.$socket.emit('setNotificationReadStatus', { notification_id: notification.notification_id, is_read: true });
      }
    },
    markNotificationsRead() {
      for (var notification of this.notifications.filter(x => x.is_read === false)) {
        this.$socket.emit('setNotificationReadStatus', { notification_id: notification.notification_id, is_read: true });
      }
    },
    updateNotificationsTimeSince() {
      for (var notification of this.notifications) {
        let date = new Date(`${notification.last_updated.replaceAll('-', '/')} UTC`);
        var seconds = Math.floor((new Date() - date) / 1000);
        var intervalType;

        var interval = Math.floor(seconds / 31536000);
        if (interval >= 1) {
          intervalType = 'year';
        } else {
          interval = Math.floor(seconds / 2592000);
          if (interval >= 1) {
            intervalType = 'month';
          } else {
            interval = Math.floor(seconds / 86400);
            if (interval >= 1) {
              intervalType = 'day';
            } else {
              interval = Math.floor(seconds / 3600);
              if (interval >= 1) {
                intervalType = "hour";
              } else {
                interval = Math.floor(seconds / 60);
                if (interval >= 1) {
                  intervalType = "minute";
                } else {
                  interval = seconds;
                  intervalType = "second";
                }
              }
            }
          }
        }

        if (interval > 1 || interval === 0) {
          intervalType += 's';
        }
        intervalType += ' ago';

        // Update the notification time_since in state
        this.$store.commit("socket.update_notification", { notification_id: notification.notification_id, time_since: `${interval} ${intervalType}` });
      }
    }
  }
}
</script>

<style scoped>
.notification-status {
  position: absolute;
  left: 0;
  border: 0.19em solid;
  border-radius: 0px 10px 10px 0px;
  height: 50%;
}
.cancel-action {
  position: absolute;
  right: 0;
  height: 100%;
  width: 7.5em;
  align-items: center;
  justify-content: center;
  background-color: red;
}
</style>