import { render, staticRenderFns } from "./SupportButton.vue?vue&type=template&id=3eb6b12d&scoped=true&"
import script from "./SupportButton.vue?vue&type=script&lang=js&"
export * from "./SupportButton.vue?vue&type=script&lang=js&"
import style0 from "./SupportButton.vue?vue&type=style&index=0&id=3eb6b12d&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3eb6b12d",
  null
  
)

export default component.exports