<template>
  <v-app>
    <Appbar
      :loggedIn="loggedIn"
      :user="user"
      :showUserInfo.sync="showUserInfo"
      :showNotificationsCard="showNotificationsCard"
      @openSearchResults="openSearchResults"
      @searchItemFlo="searchItemFlo"
      @clearSearchResults="clearSearchResults"
    ></Appbar>

    <Sidebar
      :loggedIn="loggedIn"
    ></Sidebar>

    <v-main :class="breadCrumbs[0] === '404' ? '' : 'background'">
      <!-- Card for User Details -->
      <UserCard
        v-if="loggedIn"
        :user="user"
        :show.sync="showUserInfo"
      ></UserCard>

      <!-- Card for Search Results Dropdown -->
      <SearchResults
        :loggedIn="loggedIn"
        :search="search"
        :type="type"
        :page="page"
        :pages="pages"
        :searchResults="searchResults"
        :showSearchResults="showSearchResults"
        :loadingSearchResults="loadingSearchResults"
        :hideSearchResults="hideSearchResults"
        :loadMoreResults="loadMoreResults"
        :goTo="goTo"
      ></SearchResults>

      <!-- Card for Notifications -->
      <v-scroll-y-transition>
        <div v-if="loggedIn" elevation=0 class="floating-card" style="right: 30px; width: 400px;" v-show="showNotifications" v-click-outside="hideNotificationsCard" transition="slide-x-transition">
          <NotificationsList :showNotifications.sync="showNotifications"/>
        </div>
      </v-scroll-y-transition>

      <Breadcrumbs
        v-if="loggedIn"
        :breadCrumbs="breadCrumbs"
      ></Breadcrumbs>

      <PerformingUpdate style="z-index: 99999">
      </PerformingUpdate>

      <!-- Main Page -->
      <v-container fluid class="py-0">
        <router-view></router-view>
        <StackingSnackbar></StackingSnackbar>
        <SupportButton v-if="loggedIn"/>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
import { userCore } from "@/mixins/userMixin.js";
import Appbar from "@/components/Appbar.vue";
import Sidebar from "@/components/Sidebar.vue";
import UserCard from "@/components/UserCard.vue";
import SearchResults from "@/components/search/SearchResults.vue";
import StackingSnackbar from "@/components/StackingSnackbar.vue";
import Breadcrumbs from "@/components/Breadcrumbs.vue";
import PerformingUpdate from "@/components/PerformingUpdate.vue";
import NotificationsList from "@/components/notifications/NotificationsList.vue";
import SupportButton from "@/components/support/SupportButton.vue";
export default {
  name: "App",
  mixins: [userCore],
  components: {
    Appbar,
    Sidebar,
    UserCard,
    SearchResults,
    StackingSnackbar,
    Breadcrumbs,
    PerformingUpdate,
    NotificationsList,
    SupportButton
  },
  data: () => ({
    showUserInfo: false,
    page: 1,
    pages: 0,
    search: '',
    type: '',
    searchResults: [],
    showSearchResults: false,
    loadingSearchResults: false,
    showNotifications: false,
  }),
  computed: {
    breadCrumbs () {
      /**
      * This function dynamically computes the breadcrumbs to be displayed
      * Gets the destination path and converts to array
      * Returns Array of breadcrumbs that gets used at top of page
      */
      let pathArray = this.$route.path.split('/')
      // delete last item in the array if blank (home page)
      if (pathArray[0] === "" && pathArray[1] === "") {
        pathArray.pop();
      // delete the last item in the array if blank
      } else if (pathArray[pathArray.length - 1] === "") {
        pathArray.pop();
      }
      // set the base path to '/'
      pathArray[0] = "/"
      // loops all elements in pathArray and sets breadCrumb data
      let breadCrumbs = pathArray.reduce((breadCrumbArray, path, i) => {
        // For Product breadcrumbs get the page originated from
        if (path === "products") {
          breadCrumbArray.push({
            path: path,
            to: breadCrumbArray[i - 1]
              // if breadcrumbArray contains higher level. append path
              ? breadCrumbArray[i - 1].path + path + `?page=${this.$store.getters.getPage}`
              // if last breadcrumb component just append the path
              : path + `?page=${this.$store.getters.getPage}`,
            // to: this.$router.history._startLocation,
            disabled: i + 1 === pathArray.length,
            text: this.$route.matched[i].meta.breadCrumb || path
          });
        } else {
          breadCrumbArray.push({
            // set the current path
            path: path,
            // set route to previous breadcrumb path
            to: breadCrumbArray[i - 1]
              // if breadcrumbArray contains higher level. append path
              ? breadCrumbArray[i - 1].path + path
              // if last breadcrumb component just append the path
              : path,
            // disable the last breadcrumb (current location)
            disabled: i + 1 === pathArray.length,
            // set the text from the meta field
            text: this.$route.matched[i] ? this.$route.matched[i].meta.breadCrumb : path
          });
        }
        return breadCrumbArray;
      }, [])
      
      // If page crumb is 404 return empty array to not display breadcrumbs
      if (breadCrumbs[0].text === "404") {
        return ["404"];
      } else {
        return breadCrumbs;
      }
    },
  },
  beforeCreate() {
    this.$store.commit('setSocket', this.$socket);
  },
  methods: {
    hideSearchResults(e, el) {
      // el needs to exist for function to work
      el
      // e.target is the element clicked
      // closest gets the closest element with identifier
      // if click is not on the userInfoButton this will be null
      if (this.showSearchResults && !e.target.closest('#searchResultsBar')) {
        this.showSearchResults = false;
      }
    },
    showNotificationsCard() {
      this.showNotifications = true;
      this.$store.dispatch('markNotificationsAsSeen');
    },
    hideNotificationsCard(e) {
      // e.target is the element clicked
      // closest gets the closest element with identifier
      if (this.showNotifications && !e.target.closest('#notificationsBell')) {
        this.showNotifications = false;
      }
    },
    openSearchResults() {
      if (this.search !== '') {
        this.showSearchResults = true;
      }
    },
    searchItemFlo(search, type) {
      this.search = search;
      this.type = type;
      this.page = 1;
      this.showSearchResults = true;
      this.loadingSearchResults = true;
      this.$axios
        .get(`${process.env.VUE_APP_API_BASE_URL}/search/${type}?page=${this.page}&search=${search}`, {headers: {"X-Authorization": this.$store.getters.getToken}})
        .then(result => {
          this.searchResults = result.data.items;
          this.pages = result.data.pages;
          this.loadingSearchResults = false;
        })
        .catch(err => {
          console.log(err);
        });
    },
    goTo(object) {
      this.showSearchResults = false;
      if (object.product_id) {
        this.$router.push(`/products/${object.product_id}`).catch(()=>{});
      } else if (object.brand_id) {
        this.$router.push("/brands").catch(()=>{});
      } else if (object.website_id) {
        this.$router.push(`/sites/${object.website_id}`).catch(()=>{});
      }
    },
    loadMoreResults() {
      this.page++;
      this.$axios
        .get(`${process.env.VUE_APP_API_BASE_URL}/search/${this.type}?page=${this.page}&search=${this.search}`, {headers: {"X-Authorization": this.$store.getters.getToken}})
        .then(result => {
          this.searchResults = this.searchResults.concat(result.data.items);
        })
        .catch(err => {
          console.log(err);
        });
    },
    clearSearchResults() {
      this.showSearchResults = false;
      this.search = '';
      this.type = '';
      this.page = 1;
      this.pages = 0;
      this.searchResults = [];
    }
  }
};
</script>

<style>
.background {
  background-color: rgba(245, 245, 245, 1) !important;
}
.link {
  text-decoration: none;
}
.hover-cursor {
    cursor: pointer;
}
.normal-cursor {
    cursor: initial;
}
.link:hover {
  text-decoration: underline;
  text-decoration-color: rgba(0, 0, 0, 0.38);;
}
.link-text {
  color: grey;
}
.link-text:hover {
  color: rgba(0, 0, 0, 0.38);;
}
.non-link-text {
  color: grey;
}
.image-border {
  border: thin solid rgba(0, 0, 0, 0.2);
  border-radius: 0.5rem;
}
.theme--light.v-btn.v-btn--has-bg {
  border: thin solid rgba(0, 0, 0, 0.2);
  background-color: white;
}
.cke_chrome {
  border: none !important;
}
.cke_editor_editor3 {
  border: thin solid rgba(0, 0, 0, 0.2) !important;
  border-radius: 0.5rem;
}
.cke_inner {
  border: thin solid rgba(0, 0, 0, 0.2) !important;
  border-radius: 0.5rem;
}
.cke_top {
  /* border: thin solid rgba(0, 0, 0, 0.2) !important; */
  border-radius: 0.5rem;
}
.cke_top {
  z-index: 0 !important;
  border-radius: 0.5rem 0.5rem 0rem 0rem;
}
.cke_bottom {
  /* border: thin solid rgba(0, 0, 0, 0.2) !important; */
  border-radius: 0.5rem;
}
.cke_bottom {
  z-index: 0 !important;
  border-radius: 0rem 0rem 0.5rem 0.5rem;
}
.cke_button, .cke_combo_button {
  border-radius: 0.5rem;
}
.cke_combopanel {
  border-radius: 0.5rem;
  height: 182px !important;
}
.theme--light.v-card > .v-card__text, .theme--light.v-card > .v-card__subtitle {
  color: rgba(0, 0, 0, 1);
}
.button-style {
  width: 100%;
  background-color: white;
}
.current-tab {
  background-color: rgba(0, 0, 0, 0.08) !important;
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12) !important;
  border: 0px solid rgba(0, 0, 0, 1) !important;
}
.floating-card {
  position: fixed;
  z-index: 10000;
}
</style>
