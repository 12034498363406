import Vue from "vue";
import VueRouter from "vue-router";
import store from "../store/index.js";
const Home = () => import("../views/Home.vue");
const Products = () => import("../views/Products.vue");
const Product = () => import("../views/Product.vue");
const Sites = () => import("../views/Sites.vue");
const Site = () => import("../views/Site.vue");
const ImportExport = () => import("../views/ImportExport.vue");
const Brands = () => import("../views/Brands.vue");
const Login = () => import("../views/Login.vue");
const PasswordReset = () => import("../views/PasswordReset.vue");
const PasswordResetForm = () => import("../views/PasswordResetForm.vue");
const Settings = () => import("../views/Settings.vue");
const User = () => import("../views/User.vue");
const Oauth = () => import("../views/O_Auth.vue");
const PageNotFound = () => import("../views/PageNotFound.vue");
const Notification = () => import("../views/Notification.vue");
const QuickStart = () => import("../views/support/QuickStart.vue");
const Axios = require("axios");

const DEFAULT_ROUTE_UNAUTH = () => "/login";
const DEFAULT_ROUTE_AUTH = () => "/";

const authGuard = async (to, from, next) => {
  // If going to oauth, check user is logged in
  if (to.path.split("/")[1] === "oauth") {
    // Only need to fetch user for OAuth flow as other
    // routes return 401 which is caught by interceptors
    return await store.dispatch("getUser").then(() => {
      return next();
    })
    .catch(() => {
      store.commit("setOuthLink", to.fullPath);
      return next(DEFAULT_ROUTE_UNAUTH());
    });
  }
  if (store.getters.getToken) {
    // First load when logged in
    // get organization state
    let same_referrer_domain = document.referrer.indexOf(location.protocol + "//" + location.host) === 0;
    // First load or Refresh -> from.name === null
    // Opening tab from ItemFlo -> same_referrer_domain
    if (!same_referrer_domain && from.name === null) {
      await store.dispatch('getOrganizationState');
      await store.dispatch('getOrganizationSettings');
      await store.dispatch("getNotifications");
    }
    return next();
  } else {
    return next(DEFAULT_ROUTE_UNAUTH());
  }
};

const unauthGuard = async (to, from, next) => {
  // If trying to reset password without being beta logged in
  // store full path in store and use again once beta logged in
  if (to.path === "/password-reset/edit" && !store.getters.isBetaLoggedIn) {
    store.commit("setResettingPassword", { path: to.fullPath });
  }
  if (!store.getters.getToken) {
    return next();
  }
  if (store.getters.getToken && !store.getters.getUser) {
    store
      .dispatch("getUser")
      .then(() => {
        // valid token, send to the default auth route
        return next(DEFAULT_ROUTE_AUTH());
      })
      .catch(() => {
        // invalid token, go next page
        return next();
      });
  }
  return next("/products");
};

const oauth = async (to, from, next) => {
  let hmac_check = await Axios.post(`${process.env.VUE_APP_API_BASE_URL}/shopify/check_hmac`, { query: to.query })
    .then(() => {
      return true;
    })
    .catch(err => {
      console.log(err);
      return false;
    });
  let nonce_check = await Axios.post(`${process.env.VUE_APP_API_BASE_URL}/shopify/check_nonce`, { nonce: to.query.state })
    .then(() => {
      return true;
    })
    .catch(err => {
      console.log(err);
      return false;
    });
  /* eslint-disable-next-line */
  let shop_check = /^[a-zA-Z0-9][a-zA-Z0-9\-]*.myshopify.com/.test(to.query.shop);
  if (hmac_check && shop_check && nonce_check) {
    next();
  } else {
    next("/");
  }
};

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    component: {
      render(c) {
        return c("router-view");
      },
    },
    meta: { breadCrumb: "Home" },
    children: [
      {
        path: "",
        name: "Home",
        component: Home,
        beforeEnter: authGuard,
        beforeLeave: authGuard,
      },
      {
        path: "login",
        name: "Login",
        component: Login,
        meta: { breadCrumb: "Login" },
        beforeEnter: unauthGuard,
        beforeLeave: unauthGuard,
      },
      {
        path: "password-reset",
        component: {
          render(c) {
            return c("router-view");
          },
        },
        meta: { breadCrumb: "Password Reset" },
        beforeEnter: unauthGuard,
        beforeLeave: unauthGuard,
        children: [
          {
            path: "",
            name: "Password Reset",
            component: PasswordReset,
          },
          {
            path: "edit",
            component: {
              render(c) {
                return c("router-view");
              },
            },
            meta: { breadCrumb: "Password Reset Form" },
            children: [
              {
                path: "",
                name: "Password Reset Form",
                component: PasswordResetForm,
              },
            ],
          },
        ],
      },
      {
        path: "products",
        component: {
          render(c) {
            return c("router-view");
          },
        },
        meta: { breadCrumb: "Products" },
        beforeEnter: authGuard,
        beforeLeave: authGuard,
        children: [
          {
            path: "",
            name: "Products",
            component: Products,
          },
          {
            path: ":id",
            component: {
              render(c) {
                return c("router-view");
              },
            },
            meta: { breadCrumb: "Edit Product" },
            children: [
              {
                path: "",
                name: "Product",
                component: Product,
              },
            ],
          },
        ],
      },
      {
        path: "/import-export",
        component: {
          render(c) {
            return c("router-view");
          },
        },
        meta: { breadCrumb: "Import/Export" },
        beforeEnter: authGuard,
        beforeLeave: authGuard,
        children: [
          {
            path: "",
            name: "Import Export",
            component: ImportExport,
          },
        ],
      },
      {
        path: "sites",
        component: {
          render(c) {
            return c("router-view");
          },
        },
        meta: { breadCrumb: "Sites" },
        beforeEnter: authGuard,
        beforeLeave: authGuard,
        children: [
          {
            path: "",
            name: "Sites",
            component: Sites,
          },
          {
            path: ":id",
            component: {
              render(c) {
                return c("router-view");
              },
            },
            meta: { breadCrumb: "Edit Site" },
            children: [
              {
                path: "",
                name: "Site",
                component: Site,
              },
            ],
          },
        ],
      },
      {
        path: "/brands",
        name: "Brands",
        component: Brands,
        meta: { breadCrumb: "Brands" },
        beforeEnter: authGuard,
        beforeLeave: authGuard,
      },
      {
        path: "settings",
        component: {
          render(c) {
            return c("router-view");
          },
        },
        meta: { breadCrumb: "Settings" },
        beforeEnter: authGuard,
        beforeLeave: authGuard,
        children: [
          {
            path: "",
            name: "Settings",
            component: Settings,
          },
          {
            path: "users",
            component: {
              render(c) {
                return c("router-view");
              },
            },
            meta: { breadCrumb: "Users" },
            children: [
              {
                path: "",
                name: "Users",
                component: Products,
              },
              {
                path: ":id",
                component: {
                  render(c) {
                    return c("router-view");
                  },
                },
                meta: { breadCrumb: "Edit User" },
                children: [
                  {
                    path: "",
                    name: "User",
                    component: User,
                  },
                ],
              },
            ],
          },
        ],
      },
      {
        path: "oauth",
        component: {
          render(c) {
            return c("router-view");
          },
        },
        meta: { breadCrumb: "OAuth" },
        beforeEnter: authGuard,
        beforeLeave: authGuard,
        children: [
          {
            path: "shopify",
            component: {
              render(c) {
                return c("router-view");
              },
            },
            meta: { breadCrumb: "Shopify" },
            children: [
              // Oauth Confirm
              {
                path: "confirm",
                meta: { breadCrumb: "Confirm" },
                component: Oauth,
                beforeEnter: oauth,
              },
            ],
          },
        ],
      },
      {
        path: "notification:id",
        component: Notification,
        meta: { breadCrumb: "Notification" },
        beforeEnter: authGuard,
        beforeLeave: authGuard,
      },
      {
        path: "quick-start",
        component: QuickStart,
        meta: { breadCrumb: "Quick Start" },
        beforeEnter: authGuard,
        beforeLeave: authGuard,
      },
    ],
  },
  {
    path: "*",
    meta: { breadCrumb: "404" },
    component: PageNotFound,
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  // Before each new page
  // set all mixins to false
  store.commit("setMixinActive", [false, "all"]);
  next();
});

export default router;
