<template>
  <v-app-bar color="grey darken-3" dark app clipped-left>
    <!-- Disable nav icon (hamburger) -->
    <v-app-bar-nav-icon v-if="false"></v-app-bar-nav-icon>
    <!-- Set the ItemFlo logo -->
    <img alt="Item Flo" src="../assets/logo.png" height="50px" style="cursor: pointer;" @click.stop="$router.push('/').catch(()=>{})">
    <v-spacer></v-spacer>
    <SearchBar
      :loggedIn="loggedIn"
      v-on="$listeners"
      id="searchResultsBar"
    ></SearchBar>
    <v-spacer></v-spacer>
    <!-- Bell notification btn -->
    <v-badge
      v-if="loggedIn"
      :value="unread_notifications"
      color="error"
      dot
      offset-x="12"
      offset-y="13"
    >
      <v-btn :class="{ 'bell-shake': unread_notifications }" id="notificationsBell" text @click="showNotificationsCard" fab small>
        <v-icon>{{ unread_notifications ? 'mdi-bell-ring' : 'mdi-bell' }}</v-icon>
      </v-btn>
    </v-badge>
    <!-- Hi, username btn to toggle UserInfo card -->
    <v-btn v-if="loggedIn" @click.stop="$emit('update:showUserInfo', true)" text id="userInfoButton">
      <v-icon>mdi-{{ userIcon }}</v-icon>
      Hi, {{ user.givenName }} {{ user.familyName }} 
    </v-btn>
  </v-app-bar>
</template>

<script>
import SearchBar from "@/components/search/SearchBar.vue";
export default {
  props: {
    loggedIn: Boolean,
    user: Object,
    showNotificationsCard: Function,
  },
  components: {
    SearchBar,
  },
  computed: {
    userIcon() {
      const icons = ['wheelchair-accessibility', 'emoticon-excited-outline', 'paw', 'airballoon', 'alien-outline', 'anchor', 'atom', 'baseball-bat', 'bicycle-penny-farthing', 'cactus'];
      return icons[Math.floor(Math.random() * icons.length)];
    },
    unread_notifications() {
      return !!this.$store.getters.getNotifications.filter(x => x.is_seen === false).length;
    }
  }
}
</script>

<style scoped>
.bell-shake {
  animation: 0.3s shake;
}

@keyframes shake {
  0% { transform: rotate(0deg); }
  10% { transform: rotate(-3deg); }
  20% { transform: rotate(3deg); }
  30% { transform: rotate(0deg); }
  40% { transform: rotate(3deg); }
  50% { transform: rotate(-3deg); }
  60% { transform: rotate(0deg); }
  70% { transform: rotate(-3deg); }
  80% { transform: rotate(3deg); }
  90% { transform: rotate(0deg); }
  100% { transform: rotate(-3deg); }
}
</style>