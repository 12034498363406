<template>
  <v-container>
    <Snackbar
      v-for="(snackbar, index) in snackbars"
      :key="snackbar.id"
      :snackbar="snackbar"
      :index="index"
    ></Snackbar>
  </v-container>
</template>

<script>
import Snackbar from "@/components/Snackbar.vue";

export default {
  components: {
    Snackbar,
  },
  computed: {
    snackbars() {
      return this.$store.getters.getSnackbars;
    }
  }
};
</script>
