<template>
  <div v-if="breadCrumbs.length > 1">
    <v-breadcrumbs :items="breadCrumbs" large style="padding: 18px 0px 0px 24px">
      <!-- Template for creating clickable breadcrumb links -->
      <template v-slot:item="props">
        <!-- If breadcrumbs is not marked disabled create a link -->
        <router-link :to="props.item.to" v-if="!props.item.disabled" class="link">
          <v-breadcrumbs-item class="link-text">
            {{ props.item.text }}
          </v-breadcrumbs-item>
        </router-link>

        <!-- If breadcrumb is marked disabled just draw text no link -->
        <v-breadcrumbs-item class="non-link-text" v-else>
          {{ props.item.text }}
        </v-breadcrumbs-item>
        </template>
      </v-breadcrumbs>
  </div>
</template>

<script>
export default {
  props: {
    breadCrumbs: Array,
  }
}
</script>