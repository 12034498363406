const Axios = require("axios");

export default {
  state: {
    user: localStorage.getItem("user") || null,
    token: localStorage.getItem("token") || "",
    beta_logged_in: localStorage.getItem("beta_logged_in") || false,
    role_access: localStorage.getItem("role_access") || false,
    forgotten_user: localStorage.getItem("forgotten_user") || null,
    resetting_password: localStorage.getItem("resetting_password") || false,
    organization_settings: localStorage.getItem("organization_settings") || null,
    dashboards: localStorage.getItem("dashboards") || null,
    image_settings: localStorage.getItem("image_settings") || null,
    organization_state: localStorage.getItem("organization_state") || null,
  },
  mutations: {
    setToken(state, token) {
      state.token = token;
      localStorage.setItem("token", token);
    },
    setUser(state, user) {
      state.user = JSON.stringify(user);
      localStorage.setItem("user", JSON.stringify(user));
    },
    setOrganizationState(state, new_organization_state) {
      let organization_state;
      // State never set before
      if (state.organization_state === null) {
        organization_state = new_organization_state;
      } else {
        organization_state = JSON.parse(state.organization_state);
        for (var key in new_organization_state) {
          organization_state[key] = new_organization_state[key];
        }
      }
      state.organization_state = JSON.stringify(organization_state);
      localStorage.setItem("organization_state", JSON.stringify(organization_state));
    },
    editUser(state, user) {
      let state_user = JSON.parse(state.user);
      for (var key in user) {
        // Don't add user password information into state
        if (!['current_password', 'new_password', 'userNameOrEmail'].includes(key)) {
          state_user[key] = user[key];
        }
      }
      state.user = JSON.stringify(state_user);
      localStorage.setItem("user", JSON.stringify(state_user));
    },
    logout(state) {
      state.user = null;
      state.token = "";
      state.role_access = false;
      state.forgotten_user = null;
      state.resetting_password = false;
      state.organization_settings = null;
      state.organization_state = null;
      state.image_settings = null;
      this.state.products.search = "{}";
      this.state.products.page = 1;
    },
    setBetaLoggedIn(state) {
      state.beta_logged_in = true;
      localStorage.setItem("beta_logged_in", true);
    },
    setRoleAccess(state, role_access) {
      state.role_access = JSON.stringify(role_access);
      localStorage.setItem("role_access", JSON.stringify(role_access));
    },
    setForgottenUser(state, user) {
      state.forgotten_user = JSON.stringify(user);
      localStorage.setItem("forgotten_user", JSON.stringify(user));
    },
    setResettingPassword(state, path) {
      state.resetting_password = JSON.stringify(path);
      localStorage.setItem("resetting_password", JSON.stringify(path));
    },
    setOrganizationSettings(state, settings) {
      state.organization_settings = JSON.stringify(settings);
      localStorage.setItem("organization_settings", JSON.stringify(settings));
    },
    setDashboards(state, dashboards) {
      state.dashboards = JSON.stringify(dashboards);
      localStorage.setItem("dashboards", JSON.stringify(dashboards));
    },
    setImageSettings(state, settings) {
      state.image_settings = JSON.stringify(settings);
      localStorage.setItem("image_settings", JSON.stringify(settings));
    },
    editImageSettings(state, settings) {
      let state_settings = JSON.parse(state.image_settings);
      for (var key in settings) {
        state_settings[key] = settings[key];
      }
      state.image_settings = JSON.stringify(state_settings);
      localStorage.setItem("image_settings", JSON.stringify(state_settings));
    }
  },
  actions: {
    async getOrganizationState({commit}) {
      await Axios.get(`${process.env.VUE_APP_API_BASE_URL}/organization/state`)
        .then(result => {
          commit("setOrganizationState", result.data);
        })
        .catch(err => {
          console.log(err);
          throw new Error(err);
        });
    },
    async login({ state, commit, dispatch }, loginData) {
      return Axios.post(`${process.env.VUE_APP_API_BASE_URL}/users/login`, loginData)
        .then(async result => {
          // After login, set token in state
          commit("setToken", result.data.token);
          localStorage.removeItem("forgotten_user");

          // Get the user data
          await dispatch("getUser");
          // Get the role access data
          await dispatch("getRoleAccess");
          // Get the organization settings
          await dispatch("getOrganizationSettings");
          // Get the image settings if module is enabled
          if (JSON.parse(state.organization_settings).module_image) {
            await dispatch("getImageSettings");
          }
          // Get the dashboards
          await dispatch("getDashboards");
          // Get the organization state
          await dispatch('getOrganizationState');
          // Get the notifications
          await dispatch("getNotifications");
          // Connect to the WebSocket server
          await dispatch("socket.connect");
        })
        .catch(err => {
          dispatch("invalidateMe");
          console.log(err);
          throw new Error(err);
        });
    },
    async getUser({ commit }) {
      await Axios.get(`${process.env.VUE_APP_API_BASE_URL}/users/me`)
        .then(result => {
          commit("setUser", result.data);
        })
        .catch(err => {
          localStorage.removeItem("token");
          console.log(err);
          throw new Error(err);
        });
    },
    async getRoleAccess({ commit }) {
      await Axios.get(`${process.env.VUE_APP_API_BASE_URL}/users/role`)
        .then(result => {
          commit("setRoleAccess", result.data);
        })
        .catch(err => {
          localStorage.removeItem("role_access");
          console.log(err);
          throw new Error(err);
        });
    },
    async getOrganizationSettings({ commit }) {
      await Axios.get(`${process.env.VUE_APP_API_BASE_URL}/organization/settings`)
        .then(result => {
          commit("setOrganizationSettings", result.data);
        })
        .catch(err => {
          localStorage.removeItem("organization_settings");
          console.log(err);
          throw new Error(err);
        });
    },
    async getImageSettings({ commit }) {
      await Axios.get(`${process.env.VUE_APP_API_BASE_URL}/images/settings`)
        .then(result => {
          commit("setImageSettings", result.data)
        })
        .catch(err => {
          localStorage.removeItem("image_settings");
          console.log(err);
        });
    },
    updateImageSettings({ commit, dispatch }, payload) {
      dispatch("performingUpdate", true);
      Axios.put(`${process.env.VUE_APP_API_BASE_URL}/images/settings`, payload)
        .then(() => {
          commit("editImageSettings", payload);
          dispatch("performingUpdate", false);
          dispatch("showSnackbar", {text: "Image Settings Updated Successfully!", color: "success", timeout: 2500});
        })
        .catch(err => {
          console.log(err);
          dispatch("showSnackbar", {text: err.response.statusText, color: "error", timeout: 4000});
        });
    },
    async getDashboards({ commit }) {
      await Axios.get(`${process.env.VUE_APP_API_BASE_URL}/organization/dashboards`)
        .then(result => {
          commit("setDashboards", result.data);
        })
        .catch(err => {
          localStorage.removeItem("dashboards");
          console.log(err);
        });
    },
    updateUser({ commit, dispatch }, payload) {
      dispatch("performingUpdate", true);
      Axios.put(`${process.env.VUE_APP_API_BASE_URL}/users/me`, payload)
        .then(() => {
          // Update the old data to match the new
          commit("editUser", payload);
          dispatch("performingUpdate", false);
          dispatch("showSnackbar", {text: "User Updated Successfully!", color: "success", timeout: 2500});
        })
        .catch(err => {
          console.log(err);
          dispatch("showSnackbar", {text: err.response.statusText, color: "error", timeout: 4000});
          dispatch("performingUpdate", false);
        });
    },
    logout({ dispatch }) {
      return Axios.post(`${process.env.VUE_APP_API_BASE_URL}/users/logout`)
        .then(() => {
          dispatch("invalidateMe");
        })
        .catch(err => {
          dispatch("invalidateMe");
          console.log(err);
          throw new Error(err);
        });
    },
    invalidateMe({ commit }) {
      commit("logout");
      localStorage.removeItem("token");
      localStorage.removeItem("user");
      localStorage.removeItem("search");
      localStorage.removeItem("page");
      localStorage.removeItem("role_access");
      localStorage.removeItem("forgotten_user");
      localStorage.removeItem("resetting_password");
      localStorage.removeItem("organization_settings");
      localStorage.removeItem("dashboards");
      localStorage.removeItem("image_settings");
      localStorage.removeItem("organization_state");
      localStorage.removeItem("notification");
      localStorage.removeItem("notifications");
    },
    "socket.update_sku_count"({ commit }, payload) {
      delete payload.sku_limit;
      commit("setOrganizationState", payload);
    },
    "socket.update_website_count"({ commit }, payload) {
      delete payload.website_limit;
      commit("setOrganizationState", payload);
    },
    "socket.update_brand_count"({ commit }, payload) {
      commit("setOrganizationState", payload);
    }
  },
  getters: {
    getUser: state => state.user ? JSON.parse(state.user) : null,
    getToken: state => state.token,
    isLoggedIn: state => state.user ? true : false,
    isBetaLoggedIn: state => JSON.parse(state.beta_logged_in),
    getAccessLevel: state => state.role_access ? JSON.parse(state.role_access) : null,
    getForgottenUser: state => state.forgotten_user ? JSON.parse(state.forgotten_user) : "",
    isResettingPassword: state => JSON.parse(state.resetting_password),
    getOrganizationSettings: state => JSON.parse(state.organization_settings),
    getOrganizationState: state => JSON.parse(state.organization_state),
    getDashboards: state => JSON.parse(state.dashboards),
    getImageSettings: state => JSON.parse(state.image_settings),
  }
};
