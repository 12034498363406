const Axios = require("axios");

export default {
  actions: {
    getProductRating(_, product_id) {
      return Axios.get(`${process.env.VUE_APP_API_BASE_URL}/products/${product_id}/rating`)
        .then(result => {
          return result.data
        })
        .catch(err => {
          console.log(err);
        });
    },
    updateProductRating(_, product_id) {
      Axios.put(`${process.env.VUE_APP_API_BASE_URL}/products/${product_id}/rating`)
        .catch(err => {
          console.log(err);
        });
    },
    getWeightings() {
      return Axios.get(`${process.env.VUE_APP_API_BASE_URL}/metrics/weightings`)
        .then(result => {
          return result.data;
        })
        .catch(err => {
          console.log(err);
        });
    },
    updateWeightings(_, weightings) {
      return Axios.put(`${process.env.VUE_APP_API_BASE_URL}/metrics/weightings`, weightings)
        .catch(err => {
          console.log(err);
        });
    },
    getWebsiteProductStatuses(_, website_id) {
      return Axios.get(`${process.env.VUE_APP_API_BASE_URL}/websites/${website_id}/product-statuses`)
        .then(result => {
          return result.data;
        })
        .catch(err => {
          console.log(err);
        });
    },
  }
};
