const Axios = require("axios");
import router from '../../router';

export default {
  state: {
    notifications: localStorage.getItem('notifications') || '[]',
    notification: localStorage.getItem('notification') || '{}',
  },
  mutations: {
    setNotifications(state, notifications) {
      state.notifications = JSON.stringify(notifications);
      localStorage.setItem("notifications", JSON.stringify(notifications));
    },
    setNotification(state, notification) {
      state.notification = JSON.stringify(notification);
      localStorage.setItem("notification", JSON.stringify(notification));
    },
    "socket.mark_notifications_as_seen"(state, unseen_noficiations) {
      let notifications = JSON.parse(state.notifications);
      // Iterate through subset of notifications not marked as seen 
      for (var notification of notifications.filter(x => unseen_noficiations.includes(x.notification_id))) {
        notification.is_seen = true;
      }
      state.notifications = JSON.stringify(notifications);
      localStorage.setItem("notifications", JSON.stringify(notifications));
    },
    "socket.add_notification"(state, notification) {
      let notifications = JSON.parse(state.notifications);
      notifications.push(notification);
      state.notifications = JSON.stringify(notifications);
      localStorage.setItem("notifications", JSON.stringify(notifications));
    },
    "socket.update_notification"(state, payload) {
      let notification = JSON.parse(state.notification);
      let notifications = JSON.parse(state.notifications);
      let found_notification = notifications.find(x => x.notification_id === payload.notification_id);
      for (var key in payload) {
        if (payload.notification_id === notification.notification_id) {
          notification[key] = payload[key];
        }
        found_notification[key] = payload[key];
      }
      if (payload.notification_id === notification.notification_id) {
        state.notification = JSON.stringify(notification);
        localStorage.setItem("notification", JSON.stringify(notification));
      }
      state.notifications = JSON.stringify(notifications);
      localStorage.setItem("notifications", JSON.stringify(notifications));
    },
    "socket.remove_notification"(state, notification_id) {
      let notification = JSON.parse(state.notification);
      let notifications = JSON.parse(state.notifications);
      let notification_index = notifications.findIndex(x => x.notification_id === notification_id);
      if (notification_id === notification.notification_id) {
        state.notification = JSON.stringify({});
        localStorage.setItem("notification", JSON.stringify({}));
      }
      if (notification_index !== -1) {
        notifications.splice(notification_index, 1);
        state.notifications = JSON.stringify(notifications);
        localStorage.setItem("notifications", JSON.stringify(notifications));
      }
    }
  },
  actions: {
    getNotifications({ commit }) {
      Axios.get(`${process.env.VUE_APP_API_BASE_URL}/notifications`)
        .then(result => {
          commit("setNotifications", result.data);
        })
        .catch(err => {
          console.log(err);
        });
    },
    getNotification({ commit }, notification_id) {
      Axios.get(`${process.env.VUE_APP_API_BASE_URL}/notifications/${notification_id}`)
        .then(result => {
          commit("setNotification", result.data);
        })
        .catch(err => {
          console.log(err);
        });
    },
    markNotificationsAsSeen({ getters }) {
      // Filter notifications down to only those that are not seen already
      let unseen_noficiations = getters.getNotifications.filter(x => x.is_seen === false).map(x => x.notification_id);
      if (unseen_noficiations.length) {
        getters.getSocket.emit('markNotificationsAsSeen', unseen_noficiations);
      }
    },
    deleteNotification(_, notification_id) {
      router.push('/');
      Axios.delete(`${process.env.VUE_APP_API_BASE_URL}/notifications/${notification_id}`)
        .catch(err => {
          console.log(err);
        });
    }
  },
  getters: {
    getNotifications: state => JSON.parse(state.notifications).sort((a, b) => {
      return new Date(b.last_updated) - new Date(a.last_updated);
    }),
    getNotification: state => JSON.parse(state.notification),
  }
};
