<template>
  <v-card :loading="loadingSearchResults" :disabled="loadingSearchResults" elevation="10" style="position: fixed; left: 0; right: 0; margin-left: auto; margin-right: auto; width: 800px; height: 800; z-index: 10000; border-radius: 10px" v-if="loggedIn" v-show="showSearchResults" v-click-outside="hideSearchResults">
    <v-card-title>Search Results</v-card-title>
    
    <v-divider/>

    <v-card-text style="max-height: 770px; overflow-y: auto">
      <ul v-if="searchResults.length > 0" style="padding: 0;list-style: none;">
        <li v-for="(item, i) in searchResults" :key="i">
          <a class="list-item" @click="goTo(item)">
            <div style="margin-right: 16px">
              <v-img :src="item.image_url" gradient="to bottom, rgba(0,0,0,.07), rgba(0,0,0,.09)" height="45px" width="45px" class="image-border" contain></v-img>
            </div>
            <span class="list-item-text">
              <p class="list-item-title">{{ item.name }}</p>
              <span class="font-weight-thin" style="color: black; font-size: 1em">{{ item.result_type }}</span>
            </span>
          </a>
        </li>
      </ul>
      <span v-else>No Results Found.</span>
    </v-card-text>

    <v-divider v-if="page < pages"/>
    <v-card-actions v-if="page < pages" class="justify-center" style="padding: 0">
      <v-btn @click="loadMoreResults" block text>Load More Results</v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
export default {
  props: {
    loggedIn: Boolean,
    search: String,
    type: String,
    page: Number,
    pages: Number,
    searchResults: Array,
    showSearchResults: Boolean,
    loadingSearchResults: Boolean,
    hideSearchResults: Function,
    loadMoreResults: Function,
    goTo: Function,
  },
  data() {
    return {
      headers: [
        { text: 'Name', value: 'name' },
        { text: 'Product ID', value: 'product_id' }
      ]
    }
  }
}
</script>

<style>
.list-item {
  padding: 8px;
  display: flex;
  align-items: flex-start;
  width: 100%;
  background: transparent;
  text-align: left;
  text-decoration: none;
  border-radius: 0.25rem;
  border: none;
}
.list-item:hover {
  background: rgba(230, 230, 230, 0.5);
}
.list-item-text {
  font-weight: 400;
  line-height: 1.25rem;
  text-transform: none;
  letter-spacing: normal;
  flex: 1 1;
  align-self: center;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-top: -1px;
  display: flex;
  flex-direction: column;
}
.list-item-title {
  color: black; 
  margin: 0 !important;
  font-weight: 500;
}
</style>