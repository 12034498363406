export default {
  state: {
    socket: null,
    socket_connected: false,
    snackbars: [],
    performingUpdate: false,
    oauth_nonce: localStorage.getItem("oauth_nonce") || null,
    oauth_link: localStorage.getItem("oauth_link") || null,
    mixinStatus: {},
  },
  mutations: {
    setSocket(state, socket) {
      state.socket = socket;
    },
    setOuthLink(state, oauth_link) {
      state.oauth_link = oauth_link;
      localStorage.setItem("oauth_link", oauth_link);
    },
    setMixinActive(state, [ active, mixin ]) {
      if (mixin === 'all') {
        // Set empty again
        // will default to false
        state.mixinStatus = {};
      } else {
        state.mixinStatus[mixin] = active;
      }
    },
    addSnackbar(state, payload) {
      state.snackbars.push({text: payload.text, color: payload.color, timeout: payload.timeout, id: payload.id});
    },
    addLongSnackbar(state, payload) {
      state.snackbars.push({text: payload.text, color: payload.color, timeout: -1, id: payload.id});
    },
    removeSnackbar(state, id) {
      state.snackbars.splice(state.snackbars.findIndex(x => x.id === id), 1)
    },
    setPerformingUpdate(state, payload) {
      state.performingUpdate = payload;
    },
    setOauthNonce(state, oauth_nonce) {
      state.oauth_nonce = oauth_nonce;
      localStorage.setItem("oauth_nonce", oauth_nonce);
    },
  },
  actions: {
    'socket.connect'({ state, getters }) {
      if (getters.getUser && state.socket_connected === false) {
        getters.getSocket.emit('connect_user', getters.getUser, response => {
          state.socket_connected = response;
        });
      }
    },
    'socket.disconnect'({ state }) {
      state.socket_connected = false;
    },
    showSnackbar({commit}, payload) {
      let id = Math.random();
      payload.id = id;
      commit("addSnackbar", payload)
      setTimeout(() => { commit("removeSnackbar", id) }, payload.timeout);
    },
    showLongSnackbar({commit}, payload) {
      let id = Math.random();
      payload.id = id;
      commit("addLongSnackbar", payload)
      return id;
    },
    hideLongSnackbar({commit}, payload) {
      commit("removeSnackbar", payload.id)
      commit("addSnackbar", payload)
      setTimeout(() => { commit("removeSnackbar", payload.id) }, payload.timeout);
    },
    performingUpdate({ commit }, payload) {
      commit("setPerformingUpdate", payload);
    }
  },
  getters: {
    getSocket: state => state.socket,
    getSnackbars: state => state.snackbars,
    getPerformingUpdate: state => state.performingUpdate,
    getOauthNonce: state => state.oauth_nonce ? state.oauth_nonce : null,
    getOauthLink: state => state.oauth_link ? state.oauth_link : null,
    getMixinActive: state => mixin => {
      return state.mixinStatus[mixin] !== undefined ? state.mixinStatus[mixin] : false
    }
  }
};