const Axios = require("axios");

export default {
  state: {
    brands: [],
  },
  mutations: {
    setBrands(state, brands) {
      state.brands = brands;
    },
    editBrandImage(state, brand) {
      // Find the brand and update the image URL
      state.brands.find(x => x.brand_id === brand.brand_id).image_url = brand.image_url;
    },
    addBrand(state, brand) {
      // Push the new brand into the array
      state.brands.push(brand);
    },
    editBrand(state, brand) {
      // Find the brand and update the name
      state.brands.find(x => x.brand_id === brand.brand_id).name = brand.name;
    },
    removeBrand(state, brand_id) {
      // Remove the brand from the array by index
      state.brands.splice(state.brands.findIndex(x => x.brand_id === brand_id), 1);
    }
  },
  actions: {
    getBrands({ commit }) {
      Axios.get(`${process.env.VUE_APP_API_BASE_URL}/brands`)
        .then(result => {
          commit("setBrands", result.data);
        })
        .catch(err => {
          console.log(err);
        });
    },
    uploadBrandImage({ commit }, brand) {
      let payload = new FormData();
      payload.append("photo", brand.image.file);
      payload.append("old_url", brand.image_url);
      Axios.post(`${process.env.VUE_APP_API_BASE_URL}/brands/${brand.brand_id}/logo`, payload)
        .then(result => {
          brand["image_url"] = result.data;
          commit("editBrandImage", brand);
        })
        .catch(err => {
          console.log(err);
          throw new Error(err);
        });
    },
    uploadBrand({ commit, dispatch }, payload) {
      dispatch("performingUpdate", true);
      return Axios.post(`${process.env.VUE_APP_API_BASE_URL}/brands`, {name: payload.name})
        .then(result => {
          payload["brand_id"] = result.data.brand_id;
          let new_brand = {
            brand_id: payload.brand_id,
            name: payload.name,
            product_count: 0,
            image_url: process.env.VUE_APP_NO_IMAGE_IMG
          }
          commit("addBrand", new_brand);
          if (payload.image) {
            dispatch("uploadBrandImage", payload);
          }
        })
        .then(() => {
          dispatch("performingUpdate", false);
          dispatch("showSnackbar", {text: "Brand Added Successfully!", color: "success", timeout: 2500});
          return payload.brand_id;
        })
        .catch(err => {
          console.log(err);
        });
    },
    updateBrand({ commit, dispatch }, payload) {
      dispatch("performingUpdate", true);
      Axios.put(`${process.env.VUE_APP_API_BASE_URL}/brands/${payload.brand_id}`, {name: payload.name})
        .then(() => {
          commit("editBrand", payload);
          if (payload.image) {
            dispatch("uploadBrandImage", payload);
          }
        })
        .then(() => {
          dispatch("performingUpdate", false);
          dispatch("showSnackbar", {text: "Brand Updated Successfully!", color: "success", timeout: 2500});
        })
        .catch(err => {
          console.log(err);
          dispatch("performingUpdate", false);
        });
    },
    async deleteBrand({ commit, dispatch, getters }, payload) {
      dispatch("performingUpdate", true);

      // If the brand has a product using it
      if (payload.product_count > 0) {
        // Migrate the products to use the selected brand
        await Axios.put(`${process.env.VUE_APP_API_BASE_URL}/brands/${payload.brand_id}/migrate-products/${payload.new_brand_id}`)
          .then(() => {
            // Update the product count of the selected brand
            getters.getBrand(payload.new_brand_id).product_count += payload.product_count;
          })
          .catch(err => {
            console.log(err);
          });
      }

      // Delete the brand
      await Axios.delete(`${process.env.VUE_APP_API_BASE_URL}/brands/${payload.brand_id}`)
        .then(() => {
          commit("removeBrand", payload.brand_id);
        })
        .then(() => {
          dispatch("performingUpdate", false);
          dispatch("showSnackbar", {text: `${payload.name} Deleted Successfully!`, color: "success", timeout: 2500});
        })
        .catch(err => {
          console.log(err);
          dispatch("performingUpdate", false);
        });
    }
  },
  getters: {
    getBrands: state => state.brands.sort((a, b) => {
      return a.name.localeCompare(b.name);
    }),
    getBrand: state => brand_id => state.brands.find(x => x.brand_id === brand_id),
  },
};
